.drawerContainer {
  display: flex;
  flex-direction: column;
  max-width: 775px;
  height: 100%;
  .drawerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 32px;
  }
  .drawerContent {
    height:100%;
    flex-grow: 1;
    border-top: 1px solid #dddddd;
    padding: 0 10px 1px 32px;
    overflow:hidden;
  }
  .drawerFooter {
    box-shadow: 0px -3px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 30px;
  }
}

.tableBar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 15px;
  margin-top: 15px;
  justify-content: flex-end;
  .selectFilter {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    p {
      font-size: 14px;
    }
    & > div {
      margin: 0 10px;
      width: 145px;
    }
  }
  .search {
    width: 145px;
  }
}

