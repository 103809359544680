.navigationDashboard {
  .box-style {
    background-color: white;
    width: 100%;
    border-radius: 15px;
    padding: 20px;
    padding-top: 12px;
    color: #808080;

    &.creditorBox {
      //height: 210px;
      height: auto;
      margin-top: 24px;
    }

    &.expenseBox {
      height: 652px;
    }

    &.bAccounts {
      //height: 150px;
      height: auto;
    }
  }

  .borderDiv {
    border-top: 1px solid #dddddd;
    margin-left: -20px;
    width: calc(100% + 40px);
  }

  .right {
    outline: 1px solid red;
  }

  .alignedGridItem {
    margin-top: -15px;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .circle {
    position: absolute;
    border-radius: 50%;
    background: #27ae60;
    width: 1.5em;
    height: 1.5em;

    &.absolute {
      top: 0px;
      right: 10px;
    }

    &.inline {
      margin-top: -1px;
      margin-left: 4px;
      margin-right: 5px;
    }
  }

  .textInCircle {
    justify-content: center;
    display: flex;
    margin-top: 3px;
    color: #ffffff;
    font-size: 0.8em;
  }

  .purple {
    color: #bd2b82;
  }

  .black {
    color: #354052;
    //font-weight: 500;
    //font-size: 32px;
  }

  .blackalt {
    color: #4f4f4f;
  }

  .grey2 {
    color: #828282;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    font-style: normal;
  }

  .red {
    color: #ef303b;
  }

  .marginS {
    margin-top: 15px;
  }

  .marginL {
    margin-top: 30px;
  }

  .emplTable {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    margin-top: 10px;
    position: relative;
    font-size: 12px;
    vertical-align: baseline;
  }
  .s {
    //font-size: 0.6vw;
    font-size: 12px
  }

  .circle-multiline {
    display: table-cell;
    //height: 24px;
    //width: 24px;
    width: auto;
    min-width: 16px;
    text-align: center;
    //vertical-align: middle;
    vertical-align: baseline;
    border-radius: 50%;
    background: #27ae60;
    color: white;
  }
}
