.questions {
  h2 {
    margin: 15px 0 5px;
  }
  .MuiExpansionPanelSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .middle {
      font-weight: normal;
    }
    .date {
      color: #acacac;
      font-size: 12px;
      padding-right: 15px;
      font-weight: normal;
    }
  }
}

.question-para {
  padding: 5px 20px 15px;
  position:relative;
      svg.delete {
        position: absolute;
        cursor: pointer;
        top: 0px;
        right: 20px;
      }
}

.answer-para {
  padding: 5px 20px 15px;
}
