

.MuiButton-root.activeButton {
  background-color: #b4ce5e;
  color:#000;
}
.MuiButton-root.activeButton:hover {
  background-color: #c5dd76;
}
.activeButton:disabled {
  background-color: #e3e3e3;
  color:rgb(206, 206, 206);
}

.MuiButton-root.secondaryButton {
  background-color: #494949;
  color:rgb(216, 216, 216);
}
.MuiButton-root.secondaryButton:hover {
  background-color: #757575;
}

.MuiButton-root.mutedButton {
  background-color: #eaeaea;
  color:#000;
}
.MuiButton-root.mutedButton:hover {
  background-color: #dddddd;
}



