.read-only input {
  border: none;
}
.read-only .MuiInput-underline::before,
.MuiInput-underline.Mui-disabled:before {
  border: none !important;
}
read-only .MuiInput-underline::after {
  border: none;
}

.paper.MuiPaper-root {
  padding: 15px;
}

.MuiInputBase-input.Mui-disabled {
  color: #555;
}

.addButton {
  width: 200px;
  margin-bottom: 20px !important;
}

.syncButton {
  width: 200px;
  margin-bottom: 20px !important;
  margin-left: 10px !important;
}

.settingsButton {
  color: #999 !important;
  margin-left: 10px !important;
}

.form .MuiTypography-h5 {
  color: #01a2a0;
  font-weight: 600 !important;
  font-size: 1rem !important;
  margin-top: 0px !important;
  border-top: 1px solid #01a2a017;
  padding-top: 10px;
  text-align: right;
}

.smallPaper {
  width: 400px;
  padding: 16px 24px 24px;
  margin: 64px auto 0;
}
.smallPaper > img {
  margin: 0 auto;
  display: block;
}
.smallPaper h1 {
  text-align: center;
}
.smallPaper > form > .MuiButton-containedPrimary {
  background-color: #b4ce5e;
  margin: 16px 0;
}


.question-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-popup > div{
  width: 500px;
  height: 210px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.question-popup div fieldset {
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.question-popup .buttons {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
