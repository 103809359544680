

.divider {
  width: 1px;
  background-color: #DFE3E9;
  margin: 20px 10px;
}

.form {
  display: flex;
  flex-direction: row;
  .logo{
    width: 200px;
    padding: 0 20px 0 0;
    text-align: center;
    img.logoImg{
      border-radius:50px;
      width:100px;
      height:100px;
    }
  }
}

.containerBox {
  border-top: 1px solid #DFE3E9;
  padding: 25px 0 25px;
  h3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  > div {
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
    margin-bottom: 10px;
    span{
      font-weight: bold;
      margin-right: 10px;
    }
    img {
      margin-left: 10px;
      margin-bottom: 10px;
    }
    .MuiSelect-root[tabindex="0"]{
      color:rgb(128, 128, 128);
    }
    &.error {
      color: red;
    }
  }
}

.vatCodes{
  padding: 20px;
  .right{
    text-align: right;
    strong{
      display: block;
      margin-top: 15px;
    }
  }
}