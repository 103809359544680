.invoiceManagement {
  .invoiceAction {
    margin-top: -3em;
    min-width: 10em;
    margin-right: -2em;
  }

  .invoiceDrawerContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .drawerHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 18px 32px;
    }

    .drawerContent {
      height: 100%;
      flex-grow: 1;
      border-top: 1px solid #dddddd;
      padding: 0 10px 1px 32px;
      overflow: hidden;
      width: 1250px;
      max-width: 1250px;
    }

    .drawerFooter {
      box-shadow: 0px -3px 2px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 16px 30px;
    }
  }

  .invoiceTableBar {
    justify-content: flex-start;
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
    margin-top: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 250px;

    .quickFilters {
      -webkit-flex-grow: 1;
      flex-direction: row;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-basis: 120px;
      -webkit-flex-basis: 120px;
      align-items: baseline;
      display: -webkit-flex;
    }

    .quickFilterMargin {
      width: 7.5em;
      min-width: 7.5em;
    }

    .selectFilter {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      p {
        font-size: 14px;
      }

      & > div {
        margin: 0 10px;
        width: 145px;
      }
    }

    .searchBar {
      width: auto;
      margin-left: 10px;
      margin-right: auto;
    }

    .periodTitle {
      color: #757575;
      font-weight: 700;
      margin-left: 7.5em;
    }
  }
}
