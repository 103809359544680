.read-only input {
  border: none;
}
.read-only .MuiInput-underline::before,
.MuiInput-underline.Mui-disabled:before {
  border: none !important;
}
read-only .MuiInput-underline::after {
  border: none;
}

.paper.MuiPaper-root {
  padding: 15px;
}

.MuiInputBase-input.Mui-disabled {
  color: #555;
}

.addButton {
  width: 200px;
  margin-bottom: 20px !important;
}

.syncButton {
  width: 200px;
  margin-bottom: 20px !important;
  margin-left: 10px !important;
}

.settingsButton {
  color: #999 !important;
  margin-left: 10px !important;
}

.form .MuiTypography-h5 {
  color: #01a2a0;
  font-weight: 600 !important;
  font-size: 1rem !important;
  margin-top: 0px !important;
  border-top: 1px solid #01a2a017;
  padding-top: 10px;
  text-align: right;
}

.smallPaper {
  width: 400px;
  padding: 16px 24px 24px;
  margin: 64px auto 0;
}
.smallPaper > img {
  margin: 0 auto;
  display: block;
}
.smallPaper h1 {
  text-align: center;
}
.smallPaper > form > .MuiButton-containedPrimary {
  background-color: #b4ce5e;
  margin: 16px 0;
}


.question-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-popup > div{
  width: 500px;
  height: 210px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.question-popup div fieldset {
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.question-popup .buttons {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}



.MuiButton-root.activeButton {
  background-color: #b4ce5e;
  color:#000;
}
.MuiButton-root.activeButton:hover {
  background-color: #c5dd76;
}
.activeButton:disabled {
  background-color: #e3e3e3;
  color:rgb(206, 206, 206);
}

.MuiButton-root.secondaryButton {
  background-color: #494949;
  color:rgb(216, 216, 216);
}
.MuiButton-root.secondaryButton:hover {
  background-color: #757575;
}

.MuiButton-root.mutedButton {
  background-color: #eaeaea;
  color:#000;
}
.MuiButton-root.mutedButton:hover {
  background-color: #dddddd;
}




.policyRadio {
  flex-direction: row !important;
}

.framed {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px !important;
}

.read-only .MuiOutlinedInput-root fieldset {
  border: none;
}

.read-only .MuiSelect-iconOutlined {
  display: none;
}

.read-only .MuiSelect-selectMenu {
  text-align: right;
}

.MuiExpansionPanelDetails-root{
  flex-direction: column;
}
.MuiButton-root.activeButton{
  margin-bottom: 10px;
}
.ScrollStyle {
  max-height: 150px;
  overflow-y: scroll;
}

.dataTables_wrapper tr td:nth-child(2),
.dataTables_wrapper th:nth-child(2) {
  display: none;
}

.table td {
  border-top: 0px solid !important;
}

.table-wrapper-scroll-y {
  max-height: 280px !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.hide {
  visibility: hidden;
}
h3 {
  margin-left: 15px;
}
.fileInput {
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}

.imgPreview {
  text-align: center;
  margin: 5px 15px;
  height: 200px;
  width: 500px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
}
img {
  width: 100%;
  height: 100%;
}

.previewText {
  width: 100%;
  margin-top: 20px;
  margin-left: 41px;
}

.submitButton {
  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
}

.centerText {
  text-align: center;
  width: 500px;
}

.MuiExpansionPanelDetails-root {
  flex-direction: column;
}
.react-tabs__tab-panel .MuiLinearProgress-root{
  margin-top: -10px;
  margin-bottom: 7px;
}

.drawer{position:absolute;background:#ffffff;box-shadow:0px 0px 8px rgba(0,0,0,0.25);border:1px solid #e6eaee}.drawer.horizontal{width:100%}.drawer.vertical{height:100%}.drawer.left{left:0}.drawer.right{right:0}.drawer.top{top:0}.drawer.bottom{bottom:0}

.expenseDetails{padding:35px 10px 35px 0}.expenseDetails hr{border:none;border-top:2px solid #ddd;margin-top:15px}.expenseDetails dl{display:flex;flex-direction:row;flex-wrap:wrap}.expenseDetails dl dt{width:45%;color:#868a90;font-size:14px;font-weight:500;margin-top:5px}.expenseDetails dl dd{width:55%;margin-top:5px}.expenseDetails .box{margin-top:10px}#uploadedImage{height:auto;border:1px solid #ddd;align-self:flex-start}

.mainNavigation{margin-top:55px;display:flex;flex-direction:column;flex-grow:1}.mainNavigation a{display:flex;height:54px;color:#808080;text-decoration:none;letter-spacing:1.8px;font-weight:bold;font-size:18px;line-height:22px;align-items:center}.mainNavigation a:hover{background:rgba(0,0,0,0.1)}.mainNavigation a.selected{color:#bd2b82}.mainNavigation a img{width:25px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;vertical-align:middle;margin:0 35px}.mainNavigation a .logo{width:25px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin:0 35px}.mainNavigation .spacer{flex-grow:1}.mainNavigation .icon{color:#bd2b82}

#page{display:flex;flex-direction:row}#page>div .header{height:81px}#sidebar{position:fixed;background:#FFFFFF;height:100vh;display:flex;flex-direction:column}#sidebar.collapsed{width:90px}#sidebar.full{width:360px}#sidebar .navigation{display:flex;flex-direction:column;flex-grow:1}#container{flex-grow:1;background:#EFF3F6;display:flex;flex-direction:column;height:100vh;max-height:200vh;overflow-y:scroll}#container.collapsed{padding-left:90px}#container.full{padding-left:360px}#container>.header{background:#ffffff;padding:29px;display:flex;flex-direction:row;width:100%;position:static;position:initial}#container>.header .spacer{flex-grow:1}#container>.content{position:relative;padding:29px;flex-grow:1}.mgrpdf__wrapper canvas{width:100% !important;height:auto !important}

.header{display:flex;align-items:center;position:relative;outline:1px solid #F2F2F2}.header a.logo{display:block;width:60px;height:60px;border-radius:30px;background:#fff;margin:0 10px}.header a.logo img{width:39px;height:39px;margin:10px}.header a.logo img.companyLogo{width:60px;height:60px;border-radius:30px;margin:0}.header .currentCompany{display:flex}.header .currentCompany h1{font-size:28.8px;line-height:22px;letter-spacing:0.288px;color:#808080}.header button.expansion{position:absolute;width:32px;height:32px;border-radius:16px;background:#bd2b82;right:-16px;border:none;color:#fff;line-height:9px;z-index:100}.header .selectCompany{color:#808080;cursor:pointer}.companySelection{border-top:1px solid rgba(255,255,255,0.1);position:absolute;top:81px;left:0;width:100%;background:#394047;display:none;flex-direction:column}.companySelection.opened{display:flex}.companySelection a{display:block;height:65px;color:#EAEAEA;padding:20px;text-decoration:none;font-size:18px;font-weight:bold}.companySelection a:hover{background:rgba(255,255,255,0.1)}

.bottomSelect{padding:12px;display:flex;flex-direction:row;justify-content:space-between;align-items:baseline}.bottomSelect .itemSelected{color:#fff;background:#434b54;text-align:center;display:inline-block;min-width:24px;height:24px;border-radius:12px;font-size:13px;padding:3px 5px;margin-right:10px}.bottomSelect div:last-child{font-size:12px;text-align:right;letter-spacing:-0.0230769px}.bottomSelect div:last-child span{color:#bd2b82}.drawerContainer{display:flex;flex-direction:column;max-width:775px;height:100%}.drawerContainer .drawerHeader{display:flex;flex-direction:row;justify-content:space-between;padding:18px 32px}.drawerContainer .drawerContent{height:100%;flex-grow:1;border-top:1px solid #dddddd;padding:0 10px 1px 32px}.drawerContainer .drawerFooter{box-shadow:0px -3px 2px rgba(0,0,0,0.15);display:flex;flex-direction:row;justify-content:flex-end;padding:16px 30px}.tableBar{justify-content:flex-start;display:flex;align-items:baseline;margin-bottom:15px;flex-wrap:wrap;flex-basis:250px;display:flex}.tableBar .quickFilters{flex-grow:1;-webkit-flex-grow:1;flex-direction:row;flex-wrap:wrap;-webkit-flex-wrap:wrap;flex-basis:120px;-webkit-flex-basis:120px;align-items:baseline;display:-webkit-flex}.tableBar .selectFilter{display:flex;align-items:baseline;flex-direction:column;flex-shrink:1;width:inherit}.tableBar .selectFilter p{font-size:14px}.tableBar .selectFilter>div{margin:0 10px;width:145px;width:100%}.tableBar .search{width:100%}

.bottomSelect{padding:12px;display:flex;flex-direction:row;justify-content:space-between;align-items:baseline}.bottomSelect .itemSelected{color:#fff;background:#434b54;text-align:center;display:inline-block;min-width:24px;height:24px;border-radius:12px;font-size:13px;padding:3px 5px;margin-right:10px}.bottomSelect div:last-child{font-size:12px;text-align:right;letter-spacing:-0.0230769px}.bottomSelect div:last-child span{color:#bd2b82}.drawerContainer{display:flex;flex-direction:column;max-width:775px;height:100%}.drawerContainer .drawerHeader{display:flex;flex-direction:row;justify-content:space-between;padding:18px 32px}.drawerContainer .drawerContent{height:100%;flex-grow:1;border-top:1px solid #dddddd;padding:0 10px 1px 32px;overflow:hidden}.drawerContainer .drawerFooter{box-shadow:0px -3px 2px rgba(0,0,0,0.15);display:flex;flex-direction:row;justify-content:flex-end;padding:16px 30px}.tableBar{display:flex;flex-direction:row;align-items:baseline;margin-bottom:15px}.tableBar .quickFilters{flex-grow:1}.tableBar .selectFilter{display:flex;flex-direction:row;align-items:baseline}.tableBar .selectFilter p{font-size:14px}.tableBar .selectFilter>div{margin:0 10px;width:145px}.tableBar .search{width:145px}.datePicker{margin-top:28px;border:1px solid #dedede;border-radius:5px}.datePicker label{font-weight:bold;position:relative;top:-27px}.ScrollbarsCustom-Content{display:flex}.expenseDetails>div{align-content:baseline}

.ScrollStyle {
  max-height: 150px;
  overflow-y: scroll;
}

#split-left {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
  left: 0;
  background-color: #111;
}

#split-right {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
  right: 0;
  background-color: red;
}

#centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.centered img {
  width: 150px;
  border-radius: 50%;
}


.actionButton{position:absolute !important;top:35px;right:30px}.costObjectPanel{background-color:#fff;border:1px solid #DADADA;border-radius:4px}

.divider{width:1px;background-color:#DFE3E9;margin:20px 10px}.form{display:flex;flex-direction:row}.form .logo{width:200px;padding:0 20px 0 0;text-align:center}.form .logo img.logoImg{border-radius:50px;width:100px;height:100px}.containerBox{border-top:1px solid #DFE3E9;padding:25px 0 25px}.containerBox h3{display:flex;justify-content:space-between;margin-bottom:15px}.containerBox>div{display:flex;justify-content:stretch;align-items:flex-end;margin-bottom:10px}.containerBox>div span{font-weight:bold;margin-right:10px}.containerBox>div img{margin-left:10px;margin-bottom:10px}.containerBox>div .MuiSelect-root[tabindex="0"]{color:gray}.containerBox>div.error{color:red}.vatCodes{padding:20px}.vatCodes .right{text-align:right}.vatCodes .right strong{display:block;margin-top:15px}

.employeeDetails{padding:35px 10px 35px 0}.employeeDetails hr{border:none;border-top:2px solid #ddd;margin-top:15px}.employeeDetails dl{display:flex;flex-direction:row;flex-wrap:wrap}.employeeDetails dl dt{width:45%;max-width:130px;color:#868a90;font-size:14px;font-weight:500;margin-top:5px}.employeeDetails dl dd{width:55%;min-width:calc(100% - 130px);margin-top:5px}.employeeDetails dl dd a{color:#BD2B82;text-decoration:none}.employeeDetails dl dd .cardBox{display:flex;justify-content:space-between}.employeeDetails .box{margin-top:10px}#uploadedImage{height:auto;border:1px solid #ddd;align-self:flex-start}


.tableBar{display:flex;flex-direction:row;align-items:baseline;margin-bottom:15px;margin-top:15px;justify-content:flex-end}.tableBar .selectFilter{display:flex;flex-direction:row;align-items:baseline}.tableBar .selectFilter p{font-size:14px}.tableBar .selectFilter>div{margin:0 10px;width:145px}.tableBar .search{width:145px}.pink{color:#bd2b82;margin-bottom:-7px;margin-right:10px}.innerTable{margin:-17px}.innerTable th strong{color:#7f8fa4}.errorBox{background-color:#ffcdcd;border-radius:10px;padding:15px;margin:15px 0}.statementDetails{margin-top:20px;display:flex;flex-direction:row;flex-wrap:wrap}.statementDetails dt{font-weight:bold;width:40%;margin-bottom:10px}.statementDetails dd{width:60%;margin-bottom:10px}

.questions h2{margin:15px 0 5px}.questions .MuiExpansionPanelSummary-content{display:flex;justify-content:space-between;align-items:baseline}.questions .MuiExpansionPanelSummary-content .middle{font-weight:normal}.questions .MuiExpansionPanelSummary-content .date{color:#acacac;font-size:12px;padding-right:15px;font-weight:normal}.question-para{padding:5px 20px 15px;position:relative}.question-para svg.delete{position:absolute;cursor:pointer;top:0px;right:20px}.answer-para{padding:5px 20px 15px}

.drawerContainer{display:flex;flex-direction:column;max-width:775px;height:100%}.drawerContainer .drawerHeader{display:flex;flex-direction:row;justify-content:space-between;padding:18px 32px}.drawerContainer .drawerContent{height:100%;flex-grow:1;border-top:1px solid #dddddd;padding:0 10px 1px 32px;overflow:hidden}.drawerContainer .drawerFooter{box-shadow:0px -3px 2px rgba(0,0,0,0.15);display:flex;flex-direction:row;justify-content:flex-end;padding:16px 30px}.tableBar{display:flex;flex-direction:row;align-items:baseline;margin-bottom:15px;margin-top:15px;justify-content:flex-end}.tableBar .selectFilter{display:flex;flex-direction:row;align-items:baseline}.tableBar .selectFilter p{font-size:14px}.tableBar .selectFilter>div{margin:0 10px;width:145px}.tableBar .search{width:145px}

.navigationDashboard .box-style{background-color:white;width:100%;border-radius:15px;padding:20px;padding-top:12px;color:#808080}.navigationDashboard .box-style.creditorBox{height:auto;margin-top:24px}.navigationDashboard .box-style.expenseBox{height:652px}.navigationDashboard .box-style.bAccounts{height:auto}.navigationDashboard .borderDiv{border-top:1px solid #dddddd;margin-left:-20px;width:calc(100% + 40px)}.navigationDashboard .right{outline:1px solid red}.navigationDashboard .alignedGridItem{margin-top:-15px}.navigationDashboard .title{display:flex;flex-direction:row;justify-content:space-between;margin-bottom:12px}.navigationDashboard .circle{position:absolute;border-radius:50%;background:#27ae60;width:1.5em;height:1.5em}.navigationDashboard .circle.absolute{top:0px;right:10px}.navigationDashboard .circle.inline{margin-top:-1px;margin-left:4px;margin-right:5px}.navigationDashboard .textInCircle{justify-content:center;display:flex;margin-top:3px;color:#ffffff;font-size:0.8em}.navigationDashboard .purple{color:#bd2b82}.navigationDashboard .black{color:#354052}.navigationDashboard .blackalt{color:#4f4f4f}.navigationDashboard .grey2{color:#828282;font-weight:600;font-size:18px;line-height:21px;font-style:normal}.navigationDashboard .red{color:#ef303b}.navigationDashboard .marginS{margin-top:15px}.navigationDashboard .marginL{margin-top:30px}.navigationDashboard .emplTable{display:flex;flex-direction:row;min-width:100%;margin-top:10px;position:relative;font-size:12px;vertical-align:baseline}.navigationDashboard .s{font-size:12px}.navigationDashboard .circle-multiline{display:table-cell;width:auto;min-width:16px;text-align:center;vertical-align:baseline;border-radius:50%;background:#27ae60;color:white}

.invoiceDetails{padding:35px 10px 35px 0;width:100%}.invoiceDetails hr{border:none;border-top:2px solid #ddd;margin-top:15px}.invoiceDetails dl{display:flex;flex-direction:row;flex-wrap:wrap}.invoiceDetails dl dt{width:40%;color:#868a90;font-size:14px;font-weight:500;margin-top:5px}.invoiceDetails dl dd{width:60%;margin-top:5px}.invoiceDetails dl span{margin-top:6px}.invoiceDetails .box{margin-top:10px}#uploadedImage{height:auto;border:1px solid #ddd;align-self:flex-start}

.invoiceManagement .invoiceAction{margin-top:-3em;min-width:10em;margin-right:-2em}.invoiceManagement .invoiceDrawerContainer{display:flex;flex-direction:column;height:100%}.invoiceManagement .invoiceDrawerContainer .drawerHeader{display:flex;flex-direction:row;justify-content:space-between;padding:18px 32px}.invoiceManagement .invoiceDrawerContainer .drawerContent{height:100%;flex-grow:1;border-top:1px solid #dddddd;padding:0 10px 1px 32px;overflow:hidden;width:1250px;max-width:1250px}.invoiceManagement .invoiceDrawerContainer .drawerFooter{box-shadow:0px -3px 2px rgba(0,0,0,0.15);display:flex;flex-direction:row;justify-content:flex-end;padding:16px 30px}.invoiceManagement .invoiceTableBar{justify-content:flex-start;display:flex;align-items:baseline;margin-bottom:15px;margin-top:15px;flex-direction:row;flex-wrap:wrap;flex-basis:250px}.invoiceManagement .invoiceTableBar .quickFilters{-webkit-flex-grow:1;flex-direction:row;flex-wrap:wrap;-webkit-flex-wrap:wrap;flex-basis:120px;-webkit-flex-basis:120px;align-items:baseline;display:-webkit-flex}.invoiceManagement .invoiceTableBar .quickFilterMargin{width:7.5em;min-width:7.5em}.invoiceManagement .invoiceTableBar .selectFilter{display:flex;flex-direction:row;align-items:baseline}.invoiceManagement .invoiceTableBar .selectFilter p{font-size:14px}.invoiceManagement .invoiceTableBar .selectFilter>div{margin:0 10px;width:145px}.invoiceManagement .invoiceTableBar .searchBar{width:auto;margin-left:10px;margin-right:auto}.invoiceManagement .invoiceTableBar .periodTitle{color:#757575;font-weight:700;margin-left:7.5em}

body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 350px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 350px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

* {
  margin: 0;
  padding: 0;
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

