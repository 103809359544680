.ScrollStyle {
  max-height: 150px;
  overflow-y: scroll;
}

.dataTables_wrapper tr td:nth-child(2),
.dataTables_wrapper th:nth-child(2) {
  display: none;
}

.table td {
  border-top: 0px solid !important;
}

.table-wrapper-scroll-y {
  max-height: 280px !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.hide {
  visibility: hidden;
}
h3 {
  margin-left: 15px;
}
.fileInput {
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}

.imgPreview {
  text-align: center;
  margin: 5px 15px;
  height: 200px;
  width: 500px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
}
img {
  width: 100%;
  height: 100%;
}

.previewText {
  width: 100%;
  margin-top: 20px;
  margin-left: 41px;
}

.submitButton {
  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
}

.centerText {
  text-align: center;
  width: 500px;
}

.MuiExpansionPanelDetails-root {
  flex-direction: column;
}
.react-tabs__tab-panel .MuiLinearProgress-root{
  margin-top: -10px;
  margin-bottom: 7px;
}
