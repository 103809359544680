#page {
  display: flex;
  flex-direction: row;
  & > div .header {
    height: 81px;
  }
}

//Sidebar => Navigation
#sidebar {
  //background: #434b54;
  position:fixed;
  background: #FFFFFF;
  height: 100vh;
  display:flex;
  flex-direction: column;
  &.collapsed {
    width: 90px;
    //width: 94px;
  }
  &.full {
    width: 360px;
    //width: 348px;
  }

  .navigation {
    display:flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

#container {
  flex-grow: 1;
  background: #EFF3F6;
  display:flex;
  flex-direction: column;
  height: 100vh;
  max-height: 200vh;
  overflow-y: scroll;

  &.collapsed {
    padding-left: 90px;
  }
  &.full {
    padding-left: 360px;
  }

  & > .header {
    background: #ffffff;
    padding: 29px;
    display: flex;
    flex-direction: row;

    width: 100%;
    position: initial;

    .spacer{
      flex-grow: 1;
    }
  }

  & > .content {
    position:relative;
    padding: 29px;
    flex-grow: 1;
  }
}

.mgrpdf__wrapper canvas{
  width: 100% !important;
  height:auto !important;
}
