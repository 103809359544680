.employeeDetails {
  padding: 35px 10px 35px 0;
  hr {
    border: none;
    border-top: 2px solid #ddd;
    margin-top: 15px;
  }
  dl {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    dt {
      width: 45%;
      max-width: 130px;
      color: #868a90;
      font-size: 14px;
      font-weight: 500;
      margin-top: 5px;
    }
    dd {
      width: 55%;
      min-width: calc(100% - 130px);
      margin-top: 5px;
      a{
        color: #BD2B82;
        text-decoration: none;
      }
      .cardBox {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .box {
    margin-top: 10px;
  }
}

#uploadedImage{
  height: auto;
  border: 1px solid #ddd;
  align-self: flex-start;
}
