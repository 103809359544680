.bottomSelect {
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  .itemSelected {
    color: #fff;
    background: #434b54;
    text-align: center;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    border-radius: 12px;
    font-size: 13px;
    padding: 3px 5px;
    margin-right: 10px;
  }
  div:last-child {
    font-size: 12px;
    text-align: right;
    letter-spacing: -0.0230769px;
    span {
      color: #bd2b82;
    }
  }
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  max-width: 775px;
  height: 100%;
  .drawerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 32px;
  }
  .drawerContent {
    height:100%;
    flex-grow: 1;
    border-top: 1px solid #dddddd;
    padding: 0 10px 1px 32px;
  }
  .drawerFooter {
    box-shadow: 0px -3px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 30px;
  }
}




.tableBar {
  justify-content: flex-start;
  //
  display: -webkit-flex;
  display: flex;
  //flex-direction: row;
  align-items: baseline;
  margin-bottom: 15px;

  //
  //border: 1px solid red;
  flex-wrap: wrap;
  //justify-content: flex-start;
  //flex-basis: calc(250px * 999 - 100% * 999);
  flex-basis: 250px;
  //
  //display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  //display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;         /* NEW, Spec - Opera 12.1, Firefox 20+ */

  .quickFilters {
    flex-grow: 1;
    -webkit-flex-grow: 1;
    //
    flex-direction: row;
    -webkit-flex-direction: row;
    //border: 1px solid blue;
    //white-space: pre;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-basis: 120px;
    -webkit-flex-basis: 120px;
    align-items: baseline;

    //flex-basis: calc(250px * 999 - 100% * 999);
    //min-width: 400px;
    //-webkit-flex: 1 1 10em;
    //box-sizing: border-box;
    display: -webkit-flex; /* NEW - Chrome */
    //display: flex;         /* NEW, Spec - Opera 12.1, Firefox 20+ */

    //
  }

  .selectFilter {
    display: flex;
    //flex-direction: row;
    align-items: baseline;

    //
    flex-direction: column;
    flex-shrink: 1;
    width: inherit;
    //

    p{
      font-size:14px;
    }
    & > div {
      margin: 0 10px;
      width: 145px;
      width: 100%;
    }
  }
  .search{
    //width:145px;

    //
    //width: 125px;
    width: 100%;
    //border: 1px solid blue;
    //max-width: 145px;
    //min-width: 145px;
    //
  }
}
