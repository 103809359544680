.header {
  //background: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  position: relative;
  outline:1px solid #F2F2F2;
;

  a.logo {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: #fff;
    margin: 0 10px;
    img {
      width: 39px;
      height: 39px;
      margin: 10px;
    }
    img.companyLogo{
      width: 60px;
      height: 60px;
      border-radius: 30px;
      margin:0
    }
  }

  .currentCompany {
    display: flex;
    h1 {
      font-size: 28.8px;
      line-height: 22px;
      letter-spacing: 0.288px;
      //color: #eaeaea;
      color:#808080;
    }
  }
  button.expansion {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: #bd2b82;
    right: -16px;
    border: none;
    color: #fff;
    line-height: 9px;
    z-index: 100;
  }
  //small arrow next to company name
  .selectCompany {
    //color: #dadada;
    color:#808080;
    cursor: pointer;
  }
}

.companySelection {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  //-webkit-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.75);
  position: absolute;
  top: 81px;
  left: 0;
  width: 100%;
  background: rgb(57, 64, 71);
  display: none;
  flex-direction: column;
  &.opened{
    display: flex
  }
  a {
    display: block;
    height: 65px;
    color: #EAEAEA;
    padding: 20px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;

  }
  a:hover{
    background: rgba(255, 255, 255, 0.1)
  }
}
