.drawer {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border: 1px solid #e6eaee;

  &.horizontal {
    width: 100%;
  }
  &.vertical {
    height: 100%;
  }

  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }
}
