.invoiceDetails {
  padding: 35px 10px 35px 0;
  width: 100%;

  hr {
    border: none;
    border-top: 2px solid #ddd;
    margin-top: 15px;
  }
  dl {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    dt {
      width: 40%;
      color: #868a90;
      font-size: 14px;
      font-weight: 500;
      margin-top: 5px;
    }
    dd {
      width: 60%;
      margin-top: 5px;
    }
    span {
      margin-top: 6px;
    }
  }
  .box {
    margin-top: 10px;
  }
}

#uploadedImage {
  height: auto;
  border: 1px solid #ddd;
  align-self: flex-start;
}
