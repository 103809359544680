.tableBar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 15px;
  margin-top: 15px;
  justify-content: flex-end;
  .selectFilter {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    p {
      font-size: 14px;
    }
    & > div {
      margin: 0 10px;
      width: 145px;
    }
  }
  .search {
    width: 145px;
  }
}

.pink {
  color: #bd2b82;
  margin-bottom: -7px;
  margin-right: 10px;
}
.innerTable {
  margin: -17px;
  th strong {
    color: #7f8fa4;
  }
}

.errorBox {
  background-color: rgb(255, 205, 205);
  border-radius: 10px;
  padding: 15px;
  margin: 15px 0;
}

.statementDetails {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  dt {
    font-weight: bold;
    width: 40%;
    margin-bottom: 10px;
  }
  dd {
    width: 60%;
    margin-bottom: 10px;
  }
}
