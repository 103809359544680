
.actionButton{
  position: absolute !important;
  top: 35px;
  right: 30px;
}


.costObjectPanel{
  background-color: #fff;
  border: 1px solid #DADADA;
  border-radius: 4px;
}