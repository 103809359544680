.policyRadio {
  flex-direction: row !important;
}

.framed {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px !important;
}

.read-only .MuiOutlinedInput-root fieldset {
  border: none;
}

.read-only .MuiSelect-iconOutlined {
  display: none;
}

.read-only .MuiSelect-selectMenu {
  text-align: right;
}