//Navigation items on the left, contained in sidebar (page.scss)
.mainNavigation {

  margin-top: 55px;
  display:flex;
  flex-direction: column;
  flex-grow: 1;

  a {
    display: flex;
    height: 54px;
    color:#808080;
    //color: #eaeaea;
    text-decoration: none;
    letter-spacing: 1.8px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    align-items: center;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    &.selected {
      //background: #bd2b82;
      color: #bd2b82;
      //.svg-bgcolor img {
      //  background-color: #BD2B82;
      //}
    }
    img{
      width: 25px;
      height: fit-content;
      vertical-align: middle;
      margin: 0 35px;
      //&.selected{
      //  background-color: #bd2b82;
      //}
    }
    .logo{
      width: 25px;
      height: fit-content;
      margin: 0 35px;
      //&.selected{
      //  background-color: #bd2b82;
      //}
    }
    //logo {
    //  color:#bd2b82;
    //}
  }

  .spacer{
    flex-grow: 1;
  }

  .icon{
      color:#bd2b82;
  }

}
