.bottomSelect {
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  .itemSelected {
    color: #fff;
    background: #434b54;
    text-align: center;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    border-radius: 12px;
    font-size: 13px;
    padding: 3px 5px;
    margin-right: 10px;
  }
  div:last-child {
    font-size: 12px;
    text-align: right;
    letter-spacing: -0.0230769px;
    span {
      color: #bd2b82;
    }
  }
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  max-width: 775px;
  height: 100%;
  .drawerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 32px;
  }
  .drawerContent {
    height:100%;
    flex-grow: 1;
    border-top: 1px solid #dddddd;
    padding: 0 10px 1px 32px;
    overflow:hidden;
  }
  .drawerFooter {
    box-shadow: 0px -3px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 30px;
  }
}

.tableBar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 15px;
  .quickFilters {
    flex-grow: 1;
  }
  .selectFilter {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    p{
      font-size:14px;
    }
    & > div {
      margin: 0 10px;
      width: 145px;
    }
  }
  .search{
    width:145px
  }
}


.datePicker {
  margin-top: 28px;
  border:1px solid #dedede;
  border-radius: 5px;
  label{
    font-weight: bold;
    position: relative;
    top:-27px;
  }
}


.ScrollbarsCustom-Content{
  display:flex;
}

.expenseDetails > div{
  align-content: baseline;
}